import React from "react";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import HalfScreen from "../../assets/CellphoneMockup/HalfScreen";
import ExpandableButtons from "./helpers/ExpandableButtons";
import * as S from "./styles";
import { useMediaQuery } from "react-responsive";
import FullScreen from "../../assets/CellphoneMockup/FullScreen";

const ServicesTray: React.FC = () => {
  const { t } = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <S.ServiceTrayContainer>
      <S.TitleContainer>
        <Text text={t("SERVICES")} variant="subtitle" />
        <Text text={t("ACCORDINGLY_COMPANY_NECESSITIES")} variant="title" />
      </S.TitleContainer>
      {isMobile ? <FullScreen /> : <HalfScreen />}
      <ExpandableButtons />
    </S.ServiceTrayContainer>
  );
};

export default ServicesTray;
