import styled from "styled-components";

export const TextContainer = styled.div``;

export const Text = styled.h1`
  font-family: "Mulish", sans-serif;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  cursor: pointer;
`;
