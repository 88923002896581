import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./styles";
import Text from "../Text/Text";
import MoscaBranca from "../../assets/MoscaBranca/MoscaBranca";
import Button from "../Button/Button";
import MoscaBrancaText from "../../assets/MoscaBrancaText/MoscaBrancaText";
import { useMediaQuery } from "react-responsive";
import emailjs from "@emailjs/browser";

const ContactForm: React.FC = () => {
  const { t } = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const form = useRef<HTMLFormElement>(null);

  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [showAgreementError, setShowAgreementError] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  // Inicializa o EmailJS com a chave pública ao montar o componente
  useEffect(() => {
    emailjs.init("J16fQqVBOyBgugVLW");
  }, []);

  const sendEmail = () => {
    if (!isAgreementChecked) {
      setShowAgreementError(true);
      return;
    }
    setShowAgreementError(false);

    if (form.current) {
      emailjs
        .sendForm("mosca_branca_lp", "mosca_branca_lp", form.current)
        .then(
          () => {
            console.log("SUCCESS!");
            setIsEmailSent(true);
          },
          (error) => {
            alert("Erro ao enviar dados, tente novamente.");
          }
        );
    }
  };

  const handleCheckboxChange = () => {
    setIsAgreementChecked(!isAgreementChecked);
    if (showAgreementError) setShowAgreementError(false);
  };

  const formFields = [
    {
      id: "name",
      name: "name",
      label: t("YOUR_NAME"),
      placeholder: t("YOUR_NAME"),
    },
    {
      id: "email",
      name: "email",
      label: t("YOUR_EMAIL"),
      placeholder: t("YOUR_EMAIL"),
    },
    {
      id: "phone",
      name: "phone",
      label: t("YOUR_PHONE"),
      placeholder: t("YOUR_PHONE"),
    },
  ];

  return (
    <S.ContactFormContainer>
      {isEmailSent ? (
        <Text
          text="Email enviado! Em breve entraremos em contato!"
          variant="subtitle"
        />
      ) : (
        <S.FormSection>
          <Text text={t("CHANGING_DATA_IN_STRATEGIES")} variant="title" />
          <S.Form ref={form} onSubmit={(e) => e.preventDefault()}>
            {formFields.map((field) => (
              <S.FormItemsContainer key={field.id}>
                <S.Label htmlFor={field.id}>{field.label}</S.Label>
                <S.Input
                  type="text"
                  id={field.id}
                  name={field.name}
                  placeholder={field.placeholder}
                />
              </S.FormItemsContainer>
            ))}
            <S.FormCheckboxContainer>
              <S.AgreementCheckbox
                id="agreementCheckbox"
                type="checkbox"
                checked={isAgreementChecked}
                onChange={handleCheckboxChange}
              />
              <S.AgreementLabel htmlFor="agreementCheckbox">
                {t("I_AGREE_WITH_EMAILS")}
              </S.AgreementLabel>
            </S.FormCheckboxContainer>
            {showAgreementError && <span>{t("AGREEMENT_REQUIRED")}</span>}
            <S.ScheduleDemoButtonContainer>
              <Button
                variant="default"
                label={t("SCHEDULE_DEMO")}
                onButtonClick={sendEmail}
              />
            </S.ScheduleDemoButtonContainer>
          </S.Form>
        </S.FormSection>
      )}
      {!isMobile && (
        <S.FormLogoSection>
          <MoscaBranca variant="left" width={400} />
          <MoscaBrancaText />
        </S.FormLogoSection>
      )}
    </S.ContactFormContainer>
  );
};

export default ContactForm;

