import styled from "styled-components";

export const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 20px 40px 20px;

  @media (min-width: 768px) {
    padding: 0;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
  }
`;
