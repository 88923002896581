import Logo from "../Logo/Logo";
import Button from "../Button/Button";
import * as S from "./styles";
import MenuList from "./components/MenuList/MenuList";
import { useMediaQuery } from "react-responsive";
import { NavbarProps } from "./Navbar.types";

const Navbar: React.FC<NavbarProps> = ({ scrollToSection }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const menuItems = [
    { text: "Serviços", id: "services" },
    { text: "Produtos", id: "subscriptions" },
    { text: "Perguntas frequentes", id: "faq" },
  ];

  return (
    <S.Container>
      <Logo />
      <S.MenuListItemsContainer>
        <MenuList
          items={menuItems.map(({ text, id }) => ({
            text,
            onMenuItemClick: () => scrollToSection(id),
          }))}
        />
      </S.MenuListItemsContainer>
      <S.ButtonContainer>
        <Button
          variant={isMobile ? "small" : "neutral"}
          label="Contratar"
          onButtonClick={() => scrollToSection("contact")}
        />
      </S.ButtonContainer>
    </S.Container>
  );
};

export default Navbar;
