import styled from "styled-components";

export const ExpandableButtonContainer = styled.div`
  z-index: 1;
  background-color: transparent;
  margin-top: -670px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-items: center;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    margin-top: -500px;
    gap: 80px;
  }
`;

export const ServiceTrayContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 20px;
  min-height: 600px;
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;
