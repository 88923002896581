import React from "react";
import "./App.css";

import Home from "./pages/Home/Home";

function App() {
  return (
    <div className="main-page">
      <Home />
    </div>
  );
}

export default App;
