import React from "react";
import WhatsappIcon from "../../../assets/WhatsappIcon/WhatsappIcon";
import * as S from "./styles";

const WhatsappFloatingButton: React.FC = () => {
  const phoneNumber = "5581981652295";
  const message =
    "Olá! Gostaria de saber mais sobre seus serviços e agendar uma demonstração.";

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <S.FloatingButton
      onClick={() => console.log("clicou")}
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <WhatsappIcon />
    </S.FloatingButton>
  );
};

export default WhatsappFloatingButton;
