import React from "react";

// import { Container } from './styles';

const SupportIcon: React.FC = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="22" cy="22" r="21.5" stroke="#A2D218" />
      <rect x="7" y="7" width="30" height="30" fill="url(#pattern0_543_443)" />
      <defs>
        <pattern
          id="pattern0_543_443"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_543_443" transform="scale(0.03125)" />
        </pattern>
        <image
          id="image0_543_443"
          width="32"
          height="32"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABz0lEQVR4nO1WTS8DURSdhQhhQ8K9pWFZ8R98/BQEv0H0B6j4CRbtfRObWWCNRBA2Ityri5aFhbYiNhr25HWmJTqm8zqTWuhN3m7OPeede+bOWFa3DGo7nxwmgSUluE8CBRJ8dw8UiGFPCSzuyMSQFXc5F8l+YkwTY1UJfgQdEny1eXRdY2Iht3k8SQJXDQLGA5tHl20eS6kbGNAnK4kp4sSKEjxsiGG4zF0nxyOTK4GS2xSKOcbZVhgSnCPBO9cNeGxbhKNtb9wcTkxmq58lgdO6E9mHyT5jAcSYrt+8nWDVAst47zmx1g64qsFhbP+t7FuYrwfT6BLkvmq1wLVL/tULj7xRLIQGKcF9DdJpjypASWLVE7BrAIKiK2AsFVWAfkW9HBRCg4jxTYOc/MhgVAG6hzfOt9Ag5S2TqORG/Zz8dC8JbhJjpXnFwpkpKTGe+6zpMglmNFczQDATtONNBbT4XmT8FFc6JUAxPpkBJGYBfv1UV4D89xGQYLlzAqD0p3tAMW78tgkzfk7ov5ugjUkCL8SwdXxs9XwJgDOjTRi2gpzSwn4+b3Nipk4emiSoWmXFyPZOCIjFdoMRNH9k4i7HJ6wk+Kzn/z2E3bJC1ieIdjQ+XoWqiwAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};

export default SupportIcon;
