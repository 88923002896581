import { Colors } from "styled-components";

export const colors: Colors = {
  primary: {
    main: "#759A09",
    light: "#D9D9D9",
    lighter: "#FFFFFF",
    dark: "#000000",
  },
  secondary: {
    green: "#C6FF06",
    lightGreen: "#A2D218",
    lightGray: "#444444",
  },
};
