import styled from "styled-components";

export const FaqItemContainer = styled.div`
  max-width: 539px;
  padding: 20px;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Text = styled.h1`
  font-size: 18px;
  font-weight: 700;
  color: white;
  margin-bottom: 25px;
  max-width: 80%;
`;

export const ShowMore = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: white;
  }

  &:before {
    width: 2px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &:after {
    width: 100%;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Close = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const DescriptionWrapper = styled.div<{ height: string }>`
  overflow: hidden;
  height: ${({ height }) => height};
  transition: height 0.3s ease;
  margin-bottom: 20px;
`;

export const DescriptionText = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 200;
  font-family: "Mulish", sans-serif;
  margin-bottom: 21px;
`;

export const Divider = styled.div`
  border-bottom: solid 1px #cbff34;
`;
