import React from "react";

const AutomationIcon: React.FC = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="60" height="60" fill="url(#pattern0_131_144)" />
      <defs>
        <pattern
          id="pattern0_131_144"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_131_144" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_131_144"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEv0lEQVR4nO2c3Y4URRiGe2MiCnoB7oIXoK53IB6iiW6MLh4IrqIeKa4nuktEgXig3AJR7wEkgIkhCjGiR2JWl/UK8JTd8UB+5jFfUhOG2Zqun6mq2e7+nmSSSae6vrffqan/6qpSFEVRFEVRFEVRFEVRKuAR4CPgN6BHe+kBvwLLwK6iPz2wF/iD7nFdnr1kSe6iycNm5y/ZprroOh+WMFrq5K5zrYTRW9N+yh3AVgmjFUCNLoQaXYg2G90HXgUeMp9Fc20qtNnovyxabkxLTFON/ge4AtypSXPOouV8TfrbwGXgZga9jTO6D5wG9pi8X69Je8ii5a2afF8zaXYDX3Xd6CuW/E+O1L3/AqeAGUvaGeALk2aA3PuZJe3PKYXn9HggOCXrY2I8A7wDvAA87qHpMZP2CPD0mB9kI6XwVH7WPVRqniug+fnUopto9GoBzcdTi26i0YcLaB7XaEbTNKPXgEcLaJaex58phe9Eo/vAVUtjJCbPZRf84KrQqNnrpv/eCqO/HGr5pVH6BHizREkeU7KXgI+B/UPXTzfdaBmV7Z4w3pOyogF8b/4VPfPZMNeOAvsmjLHHjE4ba/QPE/7VvwXuesSRNN9MUhUBPzbZ6NuDoXAIwCuRKzmbwEJQsPtD/7p5lm1UuYlsDI8HLv7eIx65dzkgngz5g4k2MEAYkWY/5VmSJzF5wD2fkg08GzunnczQGnGxvO1RJ6dc+JVqZNYR873YzJMbaxEXywFHvtLwpeZrR8wXYzNObqxFXAw9mWFzdOF8eheh3K3bwiUzgyNTrN5kM3hIXAwnHHlKPzkXHzhiy3x2MMmNtQjzoW/W884Bb3jkeckjz//MqPIJ81kx11xccMSWEetho3Xdt3FMauoYYT4E9Z2Bvz3yXLHct+px30agFlldd1LlxkeEbAcIzHPTI89tPQi55nHfZqAW2crgpMqNjwg1upzRixmqjlXLfccyVB0HfR6wyo2PCNOgSMNy1jQ0M4kaw1VTXcya76kawyXTGN5oWmM4yilHnjLVmYv329y9Cx2w7Ms0YLnTtQGLzxBc5pNTc6ZrQ3DhiCPfOc9uni+3ZGDjiPlubObJjbWIi6Fv20FkyXsh4TTpyx7x5ts2Tfp5QP7LCSb+vU9NibaYINEGBggLXco6GBFjIbIakeripYh4i0arN1VuCi7Ozsp8smdvRHoXZ1x1siPe5a5vN9grU53ARTOg2DIf+X5B+smTHhs2+z2CNqxPEs9XVNQGGkE2rZjpzaVJf4AJN9CsDO9ijdmoXkJsDFct503WXGt6iXXPmZjDbMRuUC8hOCVrJUp2VzY5ulgqoLnz23aFYwWM/pTENLFE7y+gWRrhpLTlsNC8OSx0oG7Wz3JYSOYu5sfMOSc9/JnKz7qHyn387cTI/EMv4vjbtiF/14+/9aWfPeh5OJaRQg50Mhj664HOB7npcUT5rMXo72rSS14/6RHlNIfuZV1yKuSrM+4/3LToy8acoddISDWjr5FoO1WLS/SOQo1ukdH6Oja4VcJofcEgZV4wKIunXedoCaN3mRehdpXfgYezGz20jne9oybPFTF5pGTL2ZNrLW8gt4BfzEbMMiVZURRFURRFURRFURSl2tn8D1IbX+RENWdzAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default AutomationIcon;
