import MoscaBranca from "../../assets/MoscaBranca/MoscaBranca";
import * as S from "./styles";

const Logo = () => {
  return (
    <>
      <S.LogoContainer>
        <MoscaBranca width={75} variant={"left"} />
        <S.TextWrapper>
          <S.Title>Mosca Branca</S.Title>
          <S.Subtitle>&lt;DEEP TECH&gt;</S.Subtitle>
        </S.TextWrapper>
      </S.LogoContainer>
    </>
  );
};

export default Logo;
