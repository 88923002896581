import { useMediaQuery } from "react-responsive";
import Button from "../Button/Button";
import { HirePlansCardsProps } from "./HirePlansCard.types";
import * as S from "./styles";

const HirePlansCards = ({
  tag,
  variant = "recommended",
  title,
  description,
  scrollToSection,
}: HirePlansCardsProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const buttonVariant = (() => {
    if (variant === "recommended") {
      return isMobile ? "defaultSmall" : "default";
    }
    if (variant === "premium" || variant === "free") {
      return "neutral";
    }
    return "default";
  })();

  return (
    <S.Container variant={variant}>
      {variant === "recommended" && <S.Tag>{tag}</S.Tag>}
      <S.ContentWrapper>
        <S.CardTitle>{title}</S.CardTitle>
        <S.CardSubtitle>{description}</S.CardSubtitle>
        <Button
          variant={buttonVariant}
          label="Contratar"
          onButtonClick={() => scrollToSection("contact")}
        />
      </S.ContentWrapper>
    </S.Container>
  );
};

export default HirePlansCards;
