import { IMenuItemProps } from "./MenuItem.types";
import * as S from "./styles";

const MenuItem: React.FC<IMenuItemProps> = ({ text, onMenuItemClick }) => {
  return (
    <S.TextContainer onClick={onMenuItemClick}>
      <S.Text>{text}</S.Text>
    </S.TextContainer>
  );
};

export default MenuItem;
