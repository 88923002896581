import React from "react";

// import { Container } from './styles';

const EmailIcon: React.FC = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="7" y="7" width="30" height="30" fill="url(#pattern0_543_442)" />
      <circle cx="22" cy="22" r="21.5" stroke="#A2D218" />
      <defs>
        <pattern
          id="pattern0_543_442"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_543_442" transform="scale(0.0208333)" />
        </pattern>
        <image
          id="image0_543_442"
          width="48"
          height="48"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVR4nO1YzWsTURB/fosoUmp2ZttSEUVEEcGDeBE82D+hV48eih8n8ejVq8eA0ry3rYf9DzSH4MUaSExmElREFBFBRCpaFULVlZcPs2x3m83npvh+MKd9M/P7vZk3CyOEgYGBgYFBCBbfHt6rGK4rxrxk/K4YvVGabOTMaw6ai+gGmdLMtCIojZq0ijKC0oPi5FQs8m715G5JWE6cNG8Uobl1FCAZriVOlqMMrnYU0Oi7pIliqEnGlc4VIHyUNFEV3UYPY70DydalsXoHBM8V47zoBrc9sV07ScY3ybUMvFdsX3FdsSM28eBhPYMlwU3JsDpC4qs6Z3D+xxISVa5lnp2QjHckw8+hESeoSYb0/WeYimrrzgL+3QKuOGRfCH53aHpGJ5GM64PrcfytGF1VhiPBfJkSnJOEudbZ2AJ8ll0i63Tw3CLbJ+pJ+xeQVZXUmQ03Xp46ruNLwj/+870I0NVY17ce9jt3yDqvGB73QDzvVOBiMJ5un3qrEtTC/HoS0Db4oYO7hYmDoT3KyDEe6Ev9xjxPbPP7u9XUfsVwSxF+28y/TwGtnoXPOllwStRHbxkvS8YPIeI/OWTdyOXETr9PuiB26VEpCT/GyT0YAW0h78LmdLpg76vfJsMXSbimq3bvxeQB/xldAcU4rwhfd5NzsALabVFUbM0FYy0X7EPaNuaw5ho+2HWuoQjwWdYh+2xU7EwJTvU7ucSQBXjNsedmKqmjrZiyas82/x2/+o0vhi2gLQRqisDRFjUS1TgLGJYJI4BNBTzTQpthyz9iSbiWNEkVYZLwa2cBjE+TJqqijPDJll5sScKFjgLuvjq2Z6z2otwkz1CMtVocx+WuZCjGXu62oNXqXaTuuyQettQ5G7kXYt+8gYGBgcF/h7+m+IOEw7BpLAAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};

export default EmailIcon;
