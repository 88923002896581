import styled from "styled-components";

export const FooterContainer = styled.footer`
  max-width: 1440px;
  height: 170px;
  margin-bottom: -20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: flex-end;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;
    justify-items: center;
  }
`;

export const FooterLogoSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 768px) {
    margin-left: 0;
    align-items: center;
  }
`;

export const FooterWorkingHoursSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
    align-items: center;
  }
`;

export const FooterContactInfoSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 40px;
`;

export const TextContainer = styled.div`
  width: 300px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  @media (max-width: 768px) {
    padding-left: 15px;
  }
`;
