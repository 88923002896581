import styled, { css } from "styled-components";

interface ContainerProps {
  variant: "recommended" | "premium" | "free";
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background-image: radial-gradient(
    circle,
    rgba(99, 121, 36, 0.6) 10%,
    rgba(255, 255, 255, 0) 110%
  );
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  ${({ variant }) => {
    if (variant === "recommended") {
      return css`
        width: 502px;
        height: 280px;
        border: 2px solid #a2d218;

        @media (max-width: 768px) {
          width: 285px;
          height: 166px;
          font-size: 16px;
        }
      `;
    } else if (variant === "premium") {
      return css`
        width: 406px;
        height: 260px;
        border: 1px solid #d9d9d9;
      `;
    } else if (variant === "free") {
      return css`
        width: 363px;
        height: 216px;
        border: 1px solid #d9d9d9;
      `;
    }
  }}
`;

export const CardTitle = styled.h1`
  font-family: "Mulish", sans-serif;
  color: white;
  font-size: 32px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const CardSubtitle = styled.h1`
  font-family: "Mulish", sans-serif;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 372px;
  @media (max-width: 768px) {
    font-size: 12px;
    width: 180px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 12px;
  }
`;

export const Tag = styled.div`
  position: absolute;
  top: -14px;
  left: 16px;
  width: 118px;
  height: 24px;
  background-color: #779904;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  border-radius: 20px;
`;
