import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0px 20px;

  @media (min-width: 768px) {
    max-width: 1440px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

export const MenuListItemsContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;
