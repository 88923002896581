import FAQItem from "../FAQItem/FAQItem";
import * as S from "./styles";
import { IFAQItem, IFAQListProps } from "./FAQItemList.types";
import Button from "../../../Button/Button";
import { useTranslation } from "react-i18next";

const FAQItemList: React.FC<IFAQListProps> = ({ items }) => {
  const { t } = useTranslation("global");

  return (
    <S.Container>
      {items.map(({ text, description }: IFAQItem, index: number) => {
        return <FAQItem key={index} text={text} description={description} />;
      })}
      <S.ScheduleContainer>
        <S.ScheduleText>{t("WANT_TO_KNOW")}</S.ScheduleText>
        <Button label={t("SCHEDULE_DEMO")} variant="default" />
      </S.ScheduleContainer>
    </S.Container>
  );
};

export default FAQItemList;
