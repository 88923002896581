import React from "react";
import CompanyInfoStats from "../../components/CompanyInfoStats/CompanyInfoStats";
import Hero from "../../components/Hero/Hero";
import * as S from "./styles";
import ServicesTray from "../../components/ServicesTray/ServicesTray";
import ContactForm from "../../components/ContactForm/ContactForm";
import FAQ from "../../components/FAQ/FAQ";
import Navbar from "../../components/Navbar/Navbar";
import SubscriptionsPlans from "../../components/SubscriptionsPlans/SubscriptionsPlans";
import useScrollToSection from "../../hooks/useScrollToSection";
import Footer from "../../components/Footer/Footer";
import WhatsappFloatingButton from "../../components/Button/WhatsappFloatingButton/WhatsappFloatingButton";

const Home: React.FC = () => {
  const { scrollToSection } = useScrollToSection();

  return (
    <S.HomePageContainer>
      <WhatsappFloatingButton />
      <Navbar scrollToSection={scrollToSection} />
      <div id="hero">
        <Hero />
      </div>
      <div id="companyInfo">
        <CompanyInfoStats />
      </div>
      <div id="services">
        <ServicesTray />
      </div>
      <div id="subscriptions">
        <SubscriptionsPlans scrollToSection={scrollToSection} />
      </div>
      <div id="faq">
        <FAQ />
      </div>
      <div id="contact">
        <ContactForm />
      </div>
      <div style={{ width: "100%", maxWidth: "1440px" }} id="footer">
        <Footer />
      </div>
    </S.HomePageContainer>
  );
};

export default Home;
