import styled from "styled-components";
import { colors } from "../../styles/colors";

const variantStyles = {
  title: {
    color: colors.primary.lighter,
    fontWeight: 800,
    fontSize: "36px",
    mobileSize: "24px",
  },
  highlightTitle: {
    color: colors.secondary.green,
    fontWeight: 800,
    fontSize: "36px",
    mobileSize: "24px",
  },
  subtitle: {
    color: colors.secondary.green,
    fontWeight: 500,
    fontSize: "20px",
    textTransform: "uppercase",
    mobileSize: "16px",
  },
  description: {
    color: colors.primary.lighter,
    fontWeight: 500,
    fontSize: "16px",
    mobileSize: "16px",
  },
  boldText: {
    color: colors.primary.lighter,
    fontWeight: 800,
    fontSize: "16px",
    mobileSize: "16px",
  },
};

export const Text = styled.p<{
  variant: "title" | "subtitle" | "description" | "highlightTitle" | "boldText";
  color?: boolean;
}>`
  color: ${({ color, variant }) =>
    color ? colors.secondary.green : variantStyles[variant]?.color};
  font-weight: ${({ variant }) => variantStyles[variant]?.fontWeight};
  font-size: ${({ variant }) => variantStyles[variant]?.mobileSize};
  text-transform: ${({ variant }) =>
    variant === "subtitle" ? variantStyles[variant]?.textTransform : ""};

  @media (min-width: 768px) {
    font-size: ${({ variant }) => variantStyles[variant]?.fontSize};
  }
`;
