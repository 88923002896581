import * as S from "./styles";
import { IFAQItem } from "./FAQItem.types";
import { useEffect, useRef, useState } from "react";

const FAQItem: React.FC<IFAQItem> = ({ text, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState("0px");
  const contentRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [isOpen]);

  return (
    <S.FaqItemContainer>
      <S.HeaderContainer>
        <S.Text>{text}</S.Text>
        {isOpen ? (
          <S.Close onClick={handleToggle} />
        ) : (
          <S.ShowMore onClick={handleToggle} />
        )}
      </S.HeaderContainer>
      <S.DescriptionWrapper height={height} ref={contentRef}>
        <S.DescriptionText>{description}</S.DescriptionText>
      </S.DescriptionWrapper>
      <S.Divider />
    </S.FaqItemContainer>
  );
};

export default FAQItem;
