import React from "react";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import * as S from "./styles";
import HirePlansCards from "../HirePlansCard/HirePlansCard";
import { useMediaQuery } from "react-responsive";
import { SubscriptionsPlansProps } from "./SubscriptionsPlans.types";

const SubscriptionsPlans: React.FC<SubscriptionsPlansProps> = ({
  scrollToSection,
}) => {
  const { t } = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const data = [
    {
      variant: "free" as "free",
      title: t("PLANS_CARDS.FREE.TITLE"),
      description: t("PLANS_CARDS.FREE.TEXT"),
    },
    {
      tag: "Recomendado",
      variant: "recommended" as "recommended",
      title: t("PLANS_CARDS.STANDARD.TITLE"),
      description: t("PLANS_CARDS.STANDARD.TEXT"),
    },
    {
      variant: "premium" as "premium",
      title: t("PLANS_CARDS.PREMIUM.TITLE"),
      description: t("PLANS_CARDS.PREMIUM.TEXT"),
    },
  ];

  const filteredData = isMobile
    ? data.filter((item) => item.variant === "recommended")
    : data;

  return (
    <S.Container>
      <Text text={t("HIRE")} variant="subtitle" />
      <Text text={t("OUR_PLANS")} variant="title" />
      <S.CardWrapper>
        {filteredData.map(({ tag, variant, title, description }, index) => (
          <HirePlansCards
            key={`hire-plans-card-${index}`}
            tag={tag}
            variant={variant}
            title={title}
            description={description}
            scrollToSection={scrollToSection}
          />
        ))}
      </S.CardWrapper>
    </S.Container>
  );
};

export default SubscriptionsPlans;
