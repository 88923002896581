import styled from "styled-components";

export const HomePageContainer = styled.body`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  padding-bottom: 20px;
`;
