import styled, { css } from "styled-components";
import { TMoscaBrancaProps } from "./MoscaBranca.types";

export const LogoContainer = styled.div<{
  width: TMoscaBrancaProps["width"];
  variant: TMoscaBrancaProps["variant"];
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ width }: { width: TMoscaBrancaProps["width"] }) => {
    if (typeof width === "number") {
      return `width: ${width}px;
      `;
    }
  }}
  ${({ variant }: { variant: TMoscaBrancaProps["variant"] }) =>
    variant === "right" &&
    css`
      transform: scaleX(-1);
    `};
`;

export const StyledMoscaBranca = styled.svg<{}>``;
