import styled from "styled-components";
import { TLogoFragmentProps } from "./LogoFragment.types";

export const LogoFragmentContainer = styled.div<{
  width: TLogoFragmentProps["width"];
}>`
  ${({ width }: { width: TLogoFragmentProps["width"] }) => {
    if (typeof width === "number") {
      return `width: ${width}px;
      `;
    }
  }}
`;
