import styled from "styled-components";
import { colors } from "../../styles/colors";

const variantStyles = {
  default: {
    backgroundColor: colors.primary.main,
    border: "none",
    hoverBackgroundColor: colors.secondary.lightGreen,
  },
  neutral: {
    backgroundColor: "transparent",
    border: `1px solid ${colors.primary.light}`,
    hoverBackgroundColor: colors.primary.light,
  },
  small: {
    backgroundColor: "transparent",
    width: "99px",
    height: "29px",
    border: `1px solid ${colors.primary.light}`,
    fontWeight: "600",
    fontSize: "12px",
    hoverBackgroundColor: colors.primary.light,
  },
  defaultSmall: {
    backgroundColor: colors.primary.main,
    width: "99px",
    height: "29px",
    border: "none",
    fontWeight: "600",
    fontSize: "12px",
    hoverBackgroundColor: colors.secondary.green,
  },
};

export const Button = styled.button<{
  variant: "neutral" | "default" | "small" | "defaultSmall";
}>`
  display: flex;
  justify-content: center;
  font-weight: ${({ variant }) =>
    variant === "small" || variant === "defaultSmall"
      ? variantStyles[variant]?.fontWeight
      : "800"};
  font-size: ${({ variant }) =>
    variant === "small" || variant === "defaultSmall"
      ? variantStyles[variant]?.fontSize
      : "14px"};
  cursor: pointer;
  width: ${({ variant }) =>
    variant === "small" || variant === "defaultSmall"
      ? variantStyles[variant]?.width
      : "248px"};
  height: ${({ variant }) =>
    variant === "small" || variant === "defaultSmall"
      ? variantStyles[variant]?.height
      : "45px"};
  padding: 13px 23px;
  align-items: center;
  border-radius: 10px;
  transition: background-color 0.8s;
  color: #fff;
  background-color: ${({ variant }) => variantStyles[variant]?.backgroundColor};
  border: ${({ variant }) => variantStyles[variant]?.border};

  &:hover {
    background-color: ${({ variant }) =>
      variantStyles[variant]?.hoverBackgroundColor};
    filter: brightness(0.9);
  }
`;

export const ButtonLabel = styled.a`
  background-color: transparent;
`;
