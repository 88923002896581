import React from "react";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import MoscaBranca from "../../assets/MoscaBranca/MoscaBranca";
import LogoFragment from "../../assets/LogoFragment/LogoFragment";
import * as S from "./styles";
import Logo from "../Logo/Logo";
import { useMediaQuery } from "react-responsive";

const Hero: React.FC = () => {
  const { t } = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <S.HeroContainer>
      <S.LogoContainer>
        <MoscaBranca variant="right" width={isMobile ? 300 : 500} />
        {!isMobile && <LogoFragment width={281} />}
      </S.LogoContainer>
      <S.HeroTextContainer>
        {!isMobile && <Logo />}
        <Text text={t("HOME_TITLE")} variant="title" />
        <Text text={t("A_I")} variant="title" color={true} />
      </S.HeroTextContainer>
    </S.HeroContainer>
  );
};

export default Hero;
