import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  margin-top: 40px;
  gap: 40px;
`;

export const TitleContainer = styled.h1`
  max-width: 70%;
  text-align: center;
`;
