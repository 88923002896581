import styled from "styled-components";
import { colors } from "../../styles/colors";

export const ContactFormContainer = styled.section`
  max-width: 1200px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: flex-end;
  margin-bottom: 80px;
  margin-top: 80px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;

  @media (max-width: 768px) {
    max-width: 500px;
  }
`;

export const Label = styled.label`
  color: ${colors.primary.light};
  font-size: 18px;
`;

export const Input = styled.input`
  border: 2px solid ${colors.secondary.lightGreen};
  border-radius: 10px;
  background-color: ${colors.secondary.lightGray};
  height: 35px;
  padding: 0 10px;
  color: ${colors.primary.lighter};
  font-size: 16px;

  &::placeholder {
    color: ${colors.primary.light};
  }
`;

export const FormItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FormCheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const AgreementCheckbox = styled.input`
  &:checked {
    accent-color: ${colors.secondary.green};
  }
`;

export const AgreementLabel = styled.label`
  color: ${colors.primary.lighter};
  font-size: 18px;
`;

export const ScheduleDemoButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const FormSection = styled.div``;

export const FormLogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
