import styled from "styled-components";

export const FloatingButton = styled.a`
  z-index: 1111111;
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: transform 0.3s ease;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
  }
`;
