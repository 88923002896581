import styled from "styled-components";

export const StyledExpandableButton = styled.button<{ isExpanded: boolean }>`
  background-color: #759a09;
  color: white;
  width: 100%;
  max-width: ${({ isExpanded }) => (isExpanded ? "300px" : "100px")};
  height: auto;
  min-height: 100px;
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: ${({ isExpanded }) =>
    isExpanded ? "space-between" : "center"};
  padding: 0 20px;
  overflow: hidden;
  cursor: pointer;
  gap: 10px;
  white-space: normal;
  transition: max-width 0.5s ease;

  @media (min-width: 768px) {
    max-width: ${({ isExpanded }) => (isExpanded ? "400px" : "100px")};
  }
`;

export const IconWrapper = styled.div``;

export const TextWrapper = styled.div<{
  textPosition: "left" | "right";
  isExpanded: boolean;
}>`
  font-size: 12px;
  width: 100%;
  max-width: 80%;
  display: ${({ isExpanded }) => (isExpanded ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: ${({ textPosition }) =>
    textPosition === "left" ? "flex-start" : "flex-end"};
  margin-left: ${({ textPosition }) =>
    textPosition === "left" ? "10px" : "0"};
  margin-right: ${({ textPosition }) =>
    textPosition === "right" ? "10px" : "0"};
  text-align: ${({ textPosition }) =>
    textPosition === "left" ? "left" : "right"};
  white-space: normal;
  transition: opacity 0.8s ease;
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
`;

export const HighlightedTex = styled.span`
  font-weight: 800;
  font-size: 16px;
`;

export const DescriptionText = styled.span`
  font-size: 14px;
`;
