import MenuItem from "../MenuItem/MenuItem";
import { IMenuItem, IMenuListProps } from "./MenuList.types";
import * as S from "./styles";

const MenuList: React.FC<IMenuListProps> = ({ items }) => {
  return (
    <S.MenuListContainer>
      {items.map(({ text, onMenuItemClick }: IMenuItem, index: number) => {
        return (
          <MenuItem
            key={`selected-item-${index}`}
            text={text}
            onMenuItemClick={onMenuItemClick}
          />
        );
      })}
    </S.MenuListContainer>
  );
};

export default MenuList;
