import React, { FC } from "react";
import * as S from "./styles";
import Text from "../Text/Text";
import { TCompanyStatsText } from "./CompanyStatsText.types";

const CompanyStatsText: FC<TCompanyStatsText> = ({
  topText,
  middleHighlight,
  bottomText,
}) => {
  return (
    <S.Container>
      <Text text={topText} variant={"description"} />
      <Text text={middleHighlight} variant={"highlightTitle"} />
      <Text text={bottomText} variant={"description"} />
    </S.Container>
  );
};

export default CompanyStatsText;
