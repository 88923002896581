import { useTranslation } from "react-i18next";
import FAQItemList from "./components/FAQItemList/FAQItemList";
import * as S from "./styles";
import Text from "../Text/Text";

const FAQ = () => {
  const { t } = useTranslation("global");

  const FAQItems = [
    {
      text: t("FAQ_QUESTIONS.FAQ_1.QUESTION"),
      description: t("FAQ_QUESTIONS.FAQ_1.ANSWER"),
      onMenuItemClick: () => {
        console.log("clicou item 1");
      },
    },
    {
      text: t("FAQ_QUESTIONS.FAQ_2.QUESTION"),
      description: t("FAQ_QUESTIONS.FAQ_2.ANSWER"),
      onMenuItemClick: () => {
        console.log("clicou item 2");
      },
    },
    {
      text: t("FAQ_QUESTIONS.FAQ_3.QUESTION"),
      description: t("FAQ_QUESTIONS.FAQ_3.ANSWER"),
      onMenuItemClick: () => {
        console.log("clicou item 3");
      },
    },
    {
      text: t("FAQ_QUESTIONS.FAQ_4.QUESTION"),
      description: t("FAQ_QUESTIONS.FAQ_4.ANSWER"),
      onMenuItemClick: () => {
        console.log("clicou item 4");
      },
    },
    {
      text: t("FAQ_QUESTIONS.FAQ_5.QUESTION"),
      description: t("FAQ_QUESTIONS.FAQ_5.ANSWER"),
      onMenuItemClick: () => {
        console.log("clicou item 5");
      },
    },
    {
      text: t("FAQ_QUESTIONS.FAQ_6.QUESTION"),
      description: t("FAQ_QUESTIONS.FAQ_6.ANSWER"),
      onMenuItemClick: () => {
        console.log("clicou item 5");
      },
    },
    {
      text: t("FAQ_QUESTIONS.FAQ_7.QUESTION"),
      description: t("FAQ_QUESTIONS.FAQ_7.ANSWER"),
      onMenuItemClick: () => {
        console.log("clicou item 5");
      },
    },
  ];

  return (
    <S.ContentWrapper>
      <S.TitleContainer>
        <Text text={t("FAQ")} variant="title" />
      </S.TitleContainer>
      <FAQItemList items={FAQItems} />
    </S.ContentWrapper>
  );
};

export default FAQ;
