import React from "react";
import * as S from "./styles";
import Logo from "../Logo/Logo";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import EmailIcon from "../../assets/Icons/EmailIcon";
import WhatsappIcon from "../../assets/Icons/WhatsappIcon";
import SupportIcon from "../../assets/Icons/SupportIcon";

const Footer: React.FC = () => {
  const { t } = useTranslation("global");

  const data = [
    {
      key: "whatsapp-icon",
      icon: <WhatsappIcon />,
      name: t("CONTACT"),
      text: t("CONTACT_PHONE"),
    },
    {
      key: "email-icon",
      icon: <EmailIcon />,
      name: t("EMAIL"),
      text: t("EMAIL_ADDRESS"),
    },
    {
      key: "support-icon",
      icon: <SupportIcon />,
      name: t("SUPPORT"),
      text: t("SUPPORT_EMAIL"),
    },
  ];

  return (
    <S.FooterContainer>
      <S.FooterLogoSection>
        <Logo />
        <S.TextContainer>
          <Text variant="description" text={t("CHANGING_DATA_IN_STRATEGIES")} />
        </S.TextContainer>
      </S.FooterLogoSection>
      <S.FooterWorkingHoursSection>
        <Text variant="boldText" text={t("OPERATION")} />
        <Text variant="description" text={t("MONDAY_FRIDAY_HOURS")} />
        <Text variant="description" text={t("SATURDAY_HOURS")} />
      </S.FooterWorkingHoursSection>
      <S.FooterContactInfoSection>
        {data.map(({ key, icon, name, text }) => (
          <S.IconContainer key={key}>
            {icon}
            <Text variant="description" text={text} />
          </S.IconContainer>
        ))}
      </S.FooterContactInfoSection>
    </S.FooterContainer>
  );
};

export default Footer;
