import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Container = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1200px;
    margin-top: 20px;
  }
`;

export const Item = styled.div<{ isMiddle: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  ${({ isMiddle }) =>
    isMiddle &&
    `
    @media (min-width: 768px) {
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 70%;
        background-color: ${colors.secondary.green};
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    @media (max-width: 768px) {
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 70%;
        height: 1px;
        background-color: ${colors.secondary.green};
        left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  `}
`;
