import React, { FC } from "react";
import * as S from "./styles";
import { TButtonProps } from "./Button.types";

const Button: FC<TButtonProps> = ({ label, variant, onButtonClick }) => {
  return (
    <S.Button variant={variant} onClick={onButtonClick}>
      <S.ButtonLabel>{label}</S.ButtonLabel>
    </S.Button>
  );
};

export default Button;
