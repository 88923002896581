import React from "react";

const MaximizeProfitIcon: React.FC = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="60" height="60" fill="url(#pattern0_131_154)" />
      <defs>
        <pattern
          id="pattern0_131_154"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_131_154" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_131_154"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE1klEQVR4nO2cTWwVVRTHR4qiEhTQSIBAwJUECQtCTGDjwpUJskejiQvAFrUrXMjCnYWED93wEU1wZUJEYwLs8QMbRYRQtBpdKZ+JSVsIwSr8zM07QDN505575955b6bnl7zNe51zzvx75tw79yvLDMMwDMMwigAeAj4CdgIvA6uABwsvMMIBXgDucJ9x4AJwBHgP2AA8XcKFcRfgQ6bmKtB37yLDH+BhYEgh9usB5o27uNIAfK0Qeu29iww9ruED3gZuKET+D3jUw7zhAJ6XRk/LL1kVAMuA/cAl4E/gE2B9BX7Xi6+/5XMS2AO8AqwAZnjaWwh8ij9H0t1lK7ClwAfArYIAhoF3gCcj+nwc2AycVQhwHfhGYnwVWAn0tLE5Q2yOEsaOWPfXLoMPSb9Sw82yWT4he52tMowBXwF7JfNfBM543Me7wPHc9y9VncEahrVZ7pm9qTkGLJe4ngKuTPhtWTcJnOeWvGG5t68Hcv7WAAeVLX5qLrqy00aTjfL7SD7+KkpEKEPAW/LRvBxUgbvnXcDsSfQ57NqAMgLPkgz2EfgfycL+isUakn/Qaqm5e6UGu1ociuu9PKvQ6TFgexmhXbA+Ah/K16mIjVdQ4yo9iRWe4l8DXvMpBaXKhvRJp2K8ncBtbM2LWBLOi615gfeVF/+kiHtR+t/zg0VLILRK4AK766Su+WT5TblmXdY0gH0xBc4DzAXelAydLHvd38zNGj67sFsy2z1aB6L1FYuz/JI8ws3MXsMwDMMwjK6ZN+uTwXM3iG60cFqcAnpdV7isyAs8BsSnM98FzyABM01kL75vN0WmEfoNPz8GsDlEaPc4GH6cChHaGj5/RkOE9mEkayh4ztKEOEj7yNQEYLCbhO7NGgqt8fCuEPrH0h32LkYmqX/qtNBO5EVZwwEWa8UOMV6Eaxy+jfLqWb8Zpz557S5sIEMMxzFUEmA2sAk4KrPoo/IZku82TbbAJWFczRCa1hBAv3J154gsGO+pML76Cw0skvrvyw9ufXNFMdZbaFr7Rf4gnN+r2I5Wa6Fp1eOfKc9vwJzEsdZa6MPE4+PEsdZTaOA54pNsEU6dhT6WQOgvE8ZbP6GB5bn91rG4k3AZW2eEljennRPWzw1o3xyBrXR41sM3fl99sliGJLA8A0pfn5OOz5QxeMVf5Ezjq5QhyYQ8l5W+zpOOc8oYvOIvcqbxVcqQPG55/lL6ukY6rihj8Iq/yJnGVylDBY/e+0pf/5KOcWUMXvEXOdP4KmVIGpMByQzfxnCMDk+Y+sbvq0+W3JDO16/JZIbhRDHH0SeaIZ2vL5LJDEcTxRxHn2iGdL76k8kM2xLFHEefaIb026BvJxDZ2VySKOb6Ce0ATiQQ2sY6CkbvYo53uD2Rz2SJKHLaOUN+Pg9GFHpP4lhrLfQjymPPpmIw9YldtRZ6wokz50qIfKGKjfPJhZYTYVzGbJtmC2hmyakKg5OdihNiWINbJrU4mx5Lws5qBAkxruVMkzObViarD84KceBDY0+ZpVUu1IQ48MEWolcktP/ejZrgu5cnxEHKMeKmMhYitG1/q2j725YAR9OdrSFC98i2W0O/ZHimt9Ai9hNWQlScdofCBomcm7Tslb0btpv2PtdlAGxLcCYbhmEYhmEYhmEYhmFk9eR/zWw0cxt1a8AAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};

export default MaximizeProfitIcon;
