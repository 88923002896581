import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { TExpandableButton } from "./types";

const ExpandableButton: React.FC<TExpandableButton> = ({
  icon,
  highlightedText,
  descriptionText,
  textPosition,
  expanded,
  onToggle,
}) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (expanded) {
      const timer = setTimeout(() => setShowText(true), 400);
      return () => clearTimeout(timer);
    } else {
      setShowText(false);
    }
  }, [expanded]);

  return (
    <S.StyledExpandableButton onClick={onToggle} isExpanded={expanded}>
      <S.IconWrapper>{icon}</S.IconWrapper>
      {showText && (
        <S.TextWrapper isExpanded={expanded} textPosition={textPosition}>
          <S.HighlightedTex>{highlightedText}</S.HighlightedTex>{" "}
          <S.DescriptionText>{descriptionText}</S.DescriptionText>
        </S.TextWrapper>
      )}
    </S.StyledExpandableButton>
  );
};

export default ExpandableButton;
