import React, { FC } from "react";
import * as S from "./styles";
import { TCompanyInfoStats } from "./CompanyInfoStats.types";
import CompanyStatsText from "../CompanyStatsText/CompanyStatsText";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const CompanyInfoStats: FC<TCompanyInfoStats> = () => {
  const { t } = useTranslation("global");
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const data = [
    {
      topText: t("COMPANY_STATS.FIRST_STATS.TOP_TEXT"),
      middleHighlight: t("COMPANY_STATS.FIRST_STATS.MIDDLE_HIGHLIGHT"),
      bottomText: t("COMPANY_STATS.FIRST_STATS.BOTTOM_TEXT"),
    },
    {
      topText: t("COMPANY_STATS.SECOND_STATS.TOP_TEXT"),
      middleHighlight: t("COMPANY_STATS.SECOND_STATS.MIDDLE_HIGHLIGHT"),
      bottomText: t("COMPANY_STATS.SECOND_STATS.BOTTOM_TEXT"),
    },
    {
      topText: t("COMPANY_STATS.THIRD_STATS.TOP_TEXT"),
      middleHighlight: t("COMPANY_STATS.THIRD_STATS.MIDDLE_HIGHLIGHT"),
      bottomText: t("COMPANY_STATS.THIRD_STATS.BOTTOM_TEXT"),
    },
  ];

  const visibleData = isMobile ? [data[1]] : data;

  return (
    <S.Container>
      {visibleData.map((item, index) => (
        <S.Item key={index} isMiddle={isMobile || index === 1}>
          <CompanyStatsText
            topText={item.topText}
            bottomText={item.bottomText}
            middleHighlight={item.middleHighlight}
          />
        </S.Item>
      ))}
    </S.Container>
  );
};

export default CompanyInfoStats;
