import React from "react";

const WhatsappIcon: React.FC = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="7" y="7" width="30" height="30" fill="url(#pattern0_143_477)" />
      <circle cx="22" cy="22" r="21.5" stroke="#A2D218" />
      <defs>
        <pattern
          id="pattern0_143_477"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_143_477" transform="scale(0.0208333)" />
        </pattern>
        <image
          id="image0_143_477"
          width="48"
          height="48"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADnUlEQVR4nO1ZSWsUURAeF1zw4DqpmjEYDwExHkTBIJ7Ek4I3Fzz4C1REVDTmIgRFPHhwiYoeMq/aBSIqqAc3iCIIQmSmatocFAxRQcWoiEqiqCM1GUlms7unu2cUpuBBYPrV+75aXlW9RCJ1qUtdfMv+TGS8xQ3LLW5oJ4bLJGgbgQ8k+F1X7m975DfYp9/qnkitxeK5jcR4iARekWDGyzICL3Wv6qg6cNMXn00MpwzDN6/AixbDsBE4qTqrAp5SsIkYBn0DL/bIO0tgY2jAe3oiE9XqQQOnogWdelag4LsfNk4lxuvhg8ecN/CanhkM+O7IBGK4Ui3w9IcE441APFGdsMFynjjhP2FrBJ5G14aKwJ+1G2fpzVBzAgyD53tjc8IIne+GcUt378zpegAJnAkxH457Aq/V0blIwbY8wilo0KIUkheGLzyeHfdgfW0P/qrUzmQi4wr3GYZzoXlB8KD7a9OhtzECbSWJp6MrQsyFAVcNoHaKjspSuK689yAZFolEElrdEGh3QWB1WQICnaF5QWCvI4FcP++kaHupvZpoJPAxRAKXnAno4OGUUIw9hfu6+pumkOCj8MCjJrK48cB7FwR+WTa2jN1ncWxpmOAp13I7EnA7pGizVew9SIRKgmHYjQeG3CpMCG7OC6Nk0wwj+LSmBAzjG/dK4WNXEubnh1J8gTcdGHAICaY9WiVJKZiWpyONCw3js3KkjeAuErwTShIbwYverQOXCluLXDjl6TKCPxKMa/58k0hCq2G4Sow/3Z7jggC0VRifp0v1R1YaVhrBu9lv0ri71JmJJCwyjLcDKWQmFV/iI8no6NPmyaX0qkf+em4a1jrpt9K4zJGAWpEYXlSebPDg3BNscjyokIDgzkCaORVi6KicQLZGfMoOO3bLJDfnZdRoAvcDaadVuuwoBjKcMAwYju3QYcfh1eOIg54hTwONihF87pvAqPV+kMBN9Qqlo4vPy7yZVt+cmL7EGYHHzobAY57Akx1tDgp8AF4c9PxuSgxbaw5cRlaCcb0n8CMEqveMSEGGjoreHIbx8z8A/npFT4uUglW1Bm/8PO4awcO1DpseP4+6hjFVI6u/rShhfQw0/QEVuyG1utYG3+BzBO6VsdCX7O2kV6wdbR5TsTu04lYAfIAEDniusE6iExYx3CKBrxpO2ZxIwSqnvibbxTLsMYIXdPAgxtdq3eyMrQ8FjKz9vLbE2lX+E/9mrUtdIv+//AYEjDuqX6lfCQAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};

export default WhatsappIcon;
