import React, { useState } from "react";
import * as S from "../styles";
import ExpandableButton from "../../ExpandableButton/ExpandableButton";
import IntegrationIcon from "../../../assets/Icons/IntegrationIcon";
import { useTranslation } from "react-i18next";
import UnifiedVisionIcon from "../../../assets/Icons/UnifiedVisionIcon";
import AutomationIcon from "../../../assets/Icons/AutomationIcon";
import PlanningIcon from "../../../assets/Icons/PlanningIcon";
import MaximizeProfitIcon from "../../../assets/Icons/MaximizeProfitIcon";

interface ButtonData {
  key: string;
  icon: React.ReactNode;
}

const ExpandableButtons: React.FC = () => {
  const { t } = useTranslation("global");

  const [expandedKey, setExpandedKey] = useState<string | null>(null);

  const buttonsData: ButtonData[] = [
    { key: "AUTOMATION", icon: <AutomationIcon /> },
    { key: "PLANNING", icon: <PlanningIcon /> },
    { key: "MAXIMIZE", icon: <MaximizeProfitIcon /> },
    { key: "UNIFIED_VISION", icon: <UnifiedVisionIcon /> },
    { key: "INTEGRATION", icon: <IntegrationIcon /> },
  ];

  const handleToggle = (key: string) => {
    setExpandedKey((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <S.ExpandableButtonContainer>
      {buttonsData.map(({ key, icon }) => (
        <ExpandableButton
          key={key}
          icon={icon}
          highlightedText={t(`EXPANDABLE_BUTTONS.${key}.HIGHLIGHT_TEXT`)}
          descriptionText={t(`EXPANDABLE_BUTTONS.${key}.BOTTOM_TEXT`)}
          textPosition="left"
          expanded={expandedKey === key}
          onToggle={() => handleToggle(key)}
        />
      ))}
    </S.ExpandableButtonContainer>
  );
};

export default ExpandableButtons;
