import styled from "styled-components";
import Button from "../../../Button/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    align-items: center;
    justify-items: center;

    :last-child {
      grid-column: 2;
    }
  }
`;
export const ScheduleContainer = styled.div`
  margin: 64px 0 28px 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    width: 436px;
    height: 46px;
  }
`;

export const ScheduleText = styled.h3`
  font-family: "Mulish", sans-serif;
  color: white;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  .button {
    text-decoration: underline;
  }
`;
