import { FC } from "react";
import * as S from "./styles";
import { TText } from "./Text.types";

const Text: FC<TText> = ({ text, variant, color }) => {
  return (
    <S.Text variant={variant} color={color}>
      {text}
    </S.Text>
  );
};

export default Text;
